import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/reactKwik/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This section explains the Kwikswap Subgraph and how to interact with it. The Kwikswap subgraph indexes data from the Kwikswap contracts over time. It organizes data about pairs, tokens, Kwikswap as a whole, and more. The subgraph updates any time a transaction is made on Kwikswap. The subgraph runs on `}<a parentName="p" {...{
        "href": "https://thegraph.com/"
      }}>{`The Graph`}</a>{` protocol’s hosted service and can be openly queried.`}</p>
    <h2 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h2>
    <p><a parentName="p" {...{
        "href": "https://thegraph.com/explorer/subgraph/kwikswap/kwikswap-subgraph"
      }}>{`Subgraph Explorer`}</a>{` - sandbox for querying data and endpoints for developers.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/Kwikswap/kwikswap-subgraph"
      }}>{`Kwikswap  Subgraph`}</a>{` - source code for deployed subgraph.`}</p>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usage`}</h2>
    <p>{`The subgraph provides a snapshot of the current state of Kwikswap and also tracks historical data. It is currently used to power `}<a parentName="p" {...{
        "href": "https://info.kwikswap.org/"
      }}>{`info.kwikswap.org`}</a>{`. `}<strong parentName="p">{`It is not intended to be used as a data source for structuring transactions (contracts should be referenced directly for the most reliable live data).`}</strong></p>
    <h2 {...{
      "id": "making-queries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#making-queries",
        "aria-label": "making queries permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Making Queries`}</h2>
    <p>{`To learn more about querying a subgraph refer to `}<a parentName="p" {...{
        "href": "https://thegraph.com/docs/introduction"
      }}>{`The Graph’s documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "versions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#versions",
        "aria-label": "versions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Versions`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://thegraph.com/explorer/subgraph/kwikswap/kwikswap-subgraph"
      }}>{`Kwikswap Subgraph`}</a>{` only tracks data on Kwikswap. For Kwikswap information see the `}<a parentName="p" {...{
        "href": "https://thegraph.com/explorer/subgraph/graphprotocol/kwikswap"
      }}>{`Subgraph`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      